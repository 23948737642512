.cardRoot {
  cursor: pointer;
  user-select: none;
  padding: var(--mantine-spacing-sm);
  margin-left: 5px;
  margin-right: 5px;
  transition: all 300ms;
  z-index: 2;
  &:hover {
    scale: 1.02;
    /* box-shadow: var(--mantine-shadow-sm); */
  }
}

.selectedCardRoot {
  cursor: pointer;
  user-select: none;
  padding: var(--mantine-spacing-sm);
  margin-left: 5px;
  margin-right: 5px;
  transition: all 300ms;
  z-index: -5;
  background-color: var(--mantine-color-APKSOrange-0) ;
  &:hover {
    scale: 1.02;
    /* box-shadow: var(--mantine-shadow-sm); */
  }
}
